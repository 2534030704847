import { useI18n } from 'vue-i18n';
import type { UserTypeAccount } from '@/types';
import { Account } from '@/enums';
import { capitalize } from 'lodash';
import en from '@/locales/en.json';

export function customLabel(option: string) {
  const { t } = useI18n();

  if (option in en) {
    return capitalize(t(option));
  }
}

export function getAllNumbers(value: string) {
  return value.replace(/[a-zA-Z]/g, '');
}

export function getMeasureUnit(value: string) {
  return value.replace(/\d/g, '');
}

export function getUserStatus(userDBStatus: UserTypeAccount | undefined) {
  if (userDBStatus === undefined) return '';

  return Account[userDBStatus];
}

export function addThreeDots(word: string, length: number) {
  const wordLengthMin = length;
  const newWord = word.substring(0, length);
  return word.length < wordLengthMin ? word : `${newWord}...`;
}

export function removeSpaces(world: string) {
  return world.replace(/ /g, '');
}

export function isNumeric(world: string) {
  const expression = /^[0-9]+$/;
  return expression.test(world);
}
