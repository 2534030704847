<template>
  <RouterView />
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router';
import { onMounted } from 'vue';
import { useLanguageStore, useDarkModeStore, useOsStore } from '@/stores';
import { axiosDefault } from '@/utils';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import Bugsnag from '@bugsnag/js';
import app from '../package.json';

const darkModeStore = useDarkModeStore();
const languageStore = useLanguageStore();
const osStore = useOsStore();

onMounted(() => {
  darkModeStore.hasDarkMode();
  languageStore.setLanguage();
  axiosDefault();
  osStore.setDetectOS();

  const appVersion = app.version;

  Bugsnag.addMetadata('version', {
    appVersion,
  });
});

onMounted(() => {
  const { updateServiceWorker, needRefresh } = useRegisterSW();

  if (needRefresh.value) {
    updateServiceWorker();
    location.reload();
  }
});
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
</style>
