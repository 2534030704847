import { useFileSystemAccess } from '@vueuse/core';
import type { Ref } from 'vue';
import { ref } from 'vue';

export async function saveAudio(myMp3: File) {
  const dataType = ref('ArrayBuffer') as Ref<'Text' | 'ArrayBuffer' | 'Blob'>;
  const { saveAs, file, updateData, isSupported } = useFileSystemAccess({
    dataType,
    types: [
      {
        description: 'audio',
        accept: {
          'audio/webm': ['.webm'],
        },
      },
    ],
    excludeAcceptAllOption: true,
  });
  file.value = myMp3;
  if (!isSupported.value) {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target == null) {
        throw 'Error on converting the audio file';
      }

      const srcUrl = e.target.result;
      localStorage.setItem('audioRec', srcUrl as string);
      window.open('/audioRec.html', '_blank');
    };
    reader.readAsDataURL(file.value);
    return;
  }

  updateData();

  await saveAs({
    suggestedName: file.value?.name,
  });
}

export async function openImage() {
  const dataType = ref('ArrayBuffer') as Ref<'Text' | 'ArrayBuffer' | 'Blob'>;
  const { open, file } = useFileSystemAccess({
    dataType,
    types: [
      {
        description: 'image',
        accept: {
          'image/*': ['.jpeg'],
        },
      },
    ],
    excludeAcceptAllOption: true,
  });

  try {
    await open();
    return file.value as File;
  } catch (e) {
    throw e as Error;
  }
}

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export function formatBytes(bytes: number, decimals = 2) {
  if (Number(bytes) === 0) return '0 B';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
