import { useRecentStore, useSmsMessageStore, useUserStore } from '@/stores';
import { RouteName } from '@/enums';
import { useGroup } from '@/composables/use-group';
import { phoneMasked } from '@/utils';

export function useContact() {
  const userStore = useUserStore();
  const recentStore = useRecentStore();
  const smsMessageStore = useSmsMessageStore();

  const { participants } = useGroup();

  function addRecentDirectory(id: string) {
    const directory = userStore.directory(id);

    if (directory?.favorite) return;

    const contact = userStore.getImportedContactsByDirectoryId(Number(id));

    if (contact) {
      recentStore.removeRecentWithTypeAndId('directory', id);

      recentStore.recent.unshift({
        id: Number(id),
        name: `${contact.firstName} ${contact.lastName}`.trim(),
        type: 'directory',
        routeName: RouteName.Directory,
      });
    }
  }

  function addRecentContact(number: string) {
    const contact = userStore.getImportedContactByNumber(number);

    if (contact) {
      if (contact.isDirectory) {
        addRecentDirectory(String(contact.directory.id));
        return;
      }

      if (contact.favorite) return;

      recentStore.removeRecentWithTypeAndId('contact', contact.id.toString(10));

      recentStore.recent.unshift({
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName}`.trim(),
        type: 'contact',
        routeName: RouteName.Contact,
      });
      return;
    }

    addRecentUnknown(number);
  }

  function addRecentGroup(id: string) {
    const group = userStore.groupById(Number(id));

    if (group?.favorite) return;

    if (group) {
      recentStore.removeRecentWithTypeAndId('group', id);

      recentStore.recent.unshift({
        id: Number(id),
        name: group.name || participants.value(group, userStore.user.userId),
        type: 'group',
        routeName: RouteName.Group,
      });
    }
  }

  function addRecentUnknown(number: string) {
    const isFavorite = smsMessageStore.unknownNumberIsFavorite(number);

    if (isFavorite) return;

    const contactUnknown = smsMessageStore.getContactUnknownSmsByNumber(number);

    if (contactUnknown) {
      recentStore.removeRecentWithTypeAndId('phone', number);

      recentStore.recent.unshift({
        id: Number(number),
        name: phoneMasked(number),
        type: 'phone',
        routeName: RouteName.SendSms,
      });
    }
  }

  return {
    addRecentDirectory,
    addRecentContact,
    addRecentGroup,
  };
}
