import type { Router, RouteLocationRaw } from 'vue-router';
import newNotificationSound from '@/assets/sounds/notificatiion.mp3';
import { useWebNotification } from '@vueuse/core';
import { ref } from 'vue';

interface GroupNotification {
  title: string;
  body: string;
  url: RouteLocationRaw;
  router: Router;
}

const countBadge = ref(0);

export function useNotification({
  title,
  body,
  url,
  router,
}: GroupNotification) {
  const soundNotification = new Audio(newNotificationSound);
  soundNotification.preload = 'none';

  const { isSupported, show, onClick } = useWebNotification({
    title,
    body,
    dir: 'auto',
  });

  if (isSupported) {
    show().then(() => soundNotification.play());

    onClick(() => {
      router.push(url);
    });
  }
}

export function increaseBadge(qty: number) {
  if (navigator.setAppBadge) {
    countBadge.value += qty;
    navigator.setAppBadge(countBadge.value);
  }
}

export function decreaseBadge(qty: number) {
  if (navigator.setAppBadge && countBadge.value > 0) {
    countBadge.value = countBadge.value - qty >= 0 ? countBadge.value - qty : 0;
    navigator.setAppBadge(countBadge.value);
  }
}
