import { defineStore } from 'pinia';
import { useSocketConnectionStore } from '@/stores/socket-connection';
import { useUserStore } from '@/stores/user';
import { computed, ref, watch } from 'vue';
import type {
  Activity,
  ActivityQueue,
  ActivityUnread,
  CallTranscription,
  UserStatusEnum,
  VoicemailTranscription,
} from '@/types';
import { firstIndex, lastIndex } from '@/utils';
import { ImageSize, Time } from '@/enums';
import { useIndexesStore } from '@/stores/indexes';
import { useStatusStore } from '@/stores/user-status';
import { useArray } from '@/composables';
import { differenceInHours } from 'date-fns';

interface QueueLog {
  datetimeEnterQueue: string;
  abandon: boolean;
  datetimeAbandon: string;
  secondsWaitedInQueue: number;
  secondsInTheCall: number;
  agentAnswered: {
    number: string;
    datetimeAnswered: string;
    datetimeFinished: string;
    user: {
      switch_user_id: number;
      device: string;
      account_code: string;
      callerid: string;
    };
  };
  numberMissed?: string;
  queuename: string;
}

export interface ActivityResponse {
  id: string;
  number_hash: string;
  src_number: string;
  src_user: number | null;
  src_account_code: string | null;
  dst_number: string;
  dst_user: number | null;
  dst_account_code: string | null;
  device: string | null;
  direction: 'inbound' | 'outbound' | 'onnet-inbound' | 'onnet-outbound';
  call_type: string;
  duration: number;
  call_recorded: boolean;
  call_recorded_file: string | null;
  release_cause: string;
  disconnect_cause: string;
  raw_id: string;
  type: 'call' | 'voicemail';
  info: string | null;
  created_at: string;
  finished_at: string;
  read: boolean;
  caller_id: string | null;
  transcription: string | null;
  queue_log: string | null;
}

export const useActivityStore = defineStore('activity', () => {
  const connectionStore = useSocketConnectionStore();
  const userStore = useUserStore();
  const indexesStore = useIndexesStore();
  const statusStore = useStatusStore();

  const selectedActivity = ref<Activity>();
  const activities = ref<Activity[]>([]);
  const activityType = ref<'all' | 'call' | 'voicemail'>('all');
  const callActivities = computed(() =>
    activities.value.filter((activity) => activity.type === 'call'),
  );
  const voicemailActivities = computed(() =>
    activities.value.filter((activity) => activity.type === 'voicemail'),
  );
  const groupedActivities = ref<Activity[][]>([]);
  const moreActivities = ref(false);

  const openPlayer = ref(false);
  const openDetails = ref(false);
  const openTranscription = ref(false);

  const lastActivities = computed(
    () => activities.value[lastIndex(activities.value)],
  );

  const lastGroupedActivities = computed(
    () =>
      groupedActivities.value[lastIndex(groupedActivities.value)][firstIndex],
  );

  const unreadCallActivities = ref(0);

  const unreadVoiceMailActivities = ref(0);

  const unreadActivities = computed(
    () => unreadCallActivities.value + unreadVoiceMailActivities.value,
  );

  const hasQueue = computed(() => !!selectedActivity.value?.queue);

  const hasAgentAnswered = computed(
    () => hasQueue.value && !!selectedActivity.value?.queue?.agentAnswered,
  );

  const hasQueueUser = computed(
    () =>
      hasAgentAnswered.value &&
      !!selectedActivity.value?.queue?.agentAnswered?.user,
  );

  function handleOpenPlayer() {
    checkActivityRead();

    openPlayer.value = false;

    setTimeout(() => {
      openPlayer.value = true;
    }, 200 * Time.Millisecond);
  }

  function handleClosePlayer() {
    openPlayer.value = false;
  }

  function handleOpenDetails() {
    checkActivityRead();

    openDetails.value = true;
  }

  function handleCloseDetails() {
    openDetails.value = false;
  }

  function handleOpenTranscription() {
    const hasTranscription =
      selectedActivity.value?.type === 'call'
        ? !!selectedActivity.value?.transcription.call
        : !!selectedActivity.value?.transcription.voicemail;

    if (!hasTranscription) {
      transcriptMedia();
    }

    openTranscription.value = true;
  }

  function handleCloseTranscription() {
    openTranscription.value = false;
  }

  function syncActivitiesInitial() {
    connectionStore.ioClient?.emit(
      'sync-activities-initial',
      {
        user_id: userStore.user.userId,
        account_code: userStore.currentAccount.accountCode,
      },
      (
        response:
          | {
              data: {
                activities: ActivityResponse[];
                moreActivities: boolean;
              };
            }
          | string,
      ) => {
        if (typeof response === 'string') {
          activities.value = [];
          return;
        }

        moreActivities.value = response.data.moreActivities;
        mapActivityResponse(response.data.activities);

        setGroupActivities();
      },
    );
  }

  function syncActivitiesBatch(operation: 'oldest' | 'newest', date: string) {
    return new Promise((resolve) => {
      connectionStore.ioClient?.emit(
        'sync-activities-batch',
        {
          user_id: userStore.user.userId,
          account_code: userStore.currentAccount.accountCode,
          operation,
          data_ref: date,
        },
        (
          response:
            | {
                data: {
                  activities: ActivityResponse[];
                  moreActivities: boolean;
                };
              }
            | string,
        ) => {
          if (typeof response === 'string') {
            activities.value = [];
            return resolve(true);
          }

          moreActivities.value = response.data.moreActivities;
          mapActivityResponse(response.data.activities);

          setGroupActivities();
          resolve(true);
        },
      );
    });
  }

  function transcriptMedia() {
    connectionStore.ioClient?.emit(
      'transcript-media',
      {
        user_id: userStore.user.userId,
        account_code: userStore.currentAccount.accountCode,
        created_at: selectedActivity.value?.startedAt,
      },
      (response: { data: ActivityResponse } | string) => {
        if (typeof response === 'string') return;

        const { findIndexByValue } = useArray(activities.value);

        const index = findIndexByValue('id', response.data.id);

        if (index !== -1) {
          const type = activities.value[index].type;

          switch (type) {
            case 'call':
              activities.value[index].transcription.call = mapTranscription(
                response.data.transcription,
              ) as CallTranscription;
              break;
            case 'voicemail':
              activities.value[index].transcription.voicemail =
                mapTranscription(
                  response.data.transcription,
                ) as VoicemailTranscription;
              break;
          }
        }

        setGroupActivities();
      },
    );
  }

  function emitCheckActivityRead(startedAt: string) {
    connectionStore.ioClient?.emit(
      'check-activity-read',
      {
        user_id: userStore.user.userId,
        account_code: userStore.currentAccount.accountCode,
        created_at: startedAt,
      },
      ({
        error,
        unreads,
      }: {
        data?: ActivityResponse;
        error?: unknown;
        unreads: ActivityUnread;
      }): void => {
        if (error) return;

        const { findIndexByValue } = useArray(activities.value);

        const index = findIndexByValue('startedAt', startedAt);

        unreadCallActivities.value = unreads.calls;
        unreadVoiceMailActivities.value = unreads.voicemails;

        if (index !== -1) {
          activities.value[index].read = true;
          setGroupActivities();
        }
      },
    );
  }

  function checkActivityRead() {
    const read = selectedActivity.value?.read as boolean;

    if (read) return;

    const startedAt = selectedActivity.value?.startedAt as string;

    emitCheckActivityRead(startedAt);
  }

  function checkActivitiesRead() {
    const id = selectedActivity.value?.id as string;
    const index = groupedActivities.value.findIndex((activity) =>
      activity.find((row) => row.id === id),
    );

    if (index !== -1) {
      for (const activity of groupedActivities.value[index]) {
        const read = activity.read;

        if (read) continue;

        const startedAt = activity.startedAt;

        emitCheckActivityRead(startedAt);
      }
    }
  }

  function activitiesUnread() {
    connectionStore.ioClient?.emit(
      'activities-unread',
      {
        user_id: userStore.user.userId,
        account_code: userStore.currentAccount.accountCode,
      },
      ({ data }: { data: ActivityUnread }): void => {
        unreadCallActivities.value = data.calls;
        unreadVoiceMailActivities.value = data.voicemails;
      },
    );
  }

  function onNewActivity() {
    connectionStore.ioClient?.on(
      'new-activity',
      (response: ActivityResponse | string) => {
        if (typeof response === 'string') {
          return;
        }
        activitiesUnread();

        newActivity(response);

        setGroupActivities();
      },
    );
  }

  function onNewTranscription() {
    connectionStore.ioClient?.on(
      'new-transcription',
      (response: ActivityResponse) => {
        const { findIndexByValue } = useArray(activities.value);

        const index = findIndexByValue('id', response.id);

        if (index !== -1) {
          const type = activities.value[index].type;

          switch (type) {
            case 'call':
              activities.value[index].transcription.call = mapTranscription(
                response.transcription,
              ) as CallTranscription;
              break;
            case 'voicemail':
              activities.value[index].transcription.voicemail =
                mapTranscription(
                  response.transcription,
                ) as VoicemailTranscription;
              break;
          }
        }

        setGroupActivities();
      },
    );
  }

  function onActivityRecordedFile() {
    connectionStore.ioClient?.on(
      'activity-recorded-file',
      (response: ActivityResponse) => {
        const { findIndexByValue } = useArray(activities.value);

        const index = findIndexByValue('id', response.id);

        if (index !== -1) {
          activities.value[index].recordedFile = response.call_recorded_file;
        }

        setGroupActivities();
      },
    );
  }

  function mapCallActivity(activity: ActivityResponse): Activity {
    const {
      name: fromName,
      tag: fromTag,
      isContact: fromIsContact,
    } = getInfo(activity, 'from');
    const {
      name: toName,
      tag: toTag,
      isContact: toIsContact,
    } = getInfo(activity, 'to');

    return {
      collapse: false,
      startedAt: activity.created_at,
      endedAt: activity.finished_at,
      direction: activity.direction,
      disconnectCause: activity.disconnect_cause,
      duration: activity.duration,
      device: activity.device,
      callRecorded: activity.call_recorded,
      recordedFile: activity.call_recorded_file,
      downloadUrl: '',
      transcription: {
        [activity.type === 'call' ? 'call' : 'voicemail']: mapTranscription(
          activity.transcription,
        ),
      },
      details: setDetails(activity.release_cause),
      from: {
        id: activity.src_user,
        accountCode: activity.src_account_code,
        number: activity.src_number,
        avatar: getAvatar(activity, 'from'),
        name: fromName,
        tag: fromTag,
        isContact: fromIsContact,
        callerId: activity.direction.includes('inbound')
          ? activity.caller_id
          : null,
      },
      id: activity.id,
      to: {
        id: activity.dst_user,
        accountCode: activity.dst_account_code,
        number: activity.dst_number,
        avatar: getAvatar(activity, 'to'),
        name: toName,
        tag: toTag,
        isContact: toIsContact,
        callerId: activity.direction.includes('inbound')
          ? null
          : activity.caller_id,
      },
      type: activity.type,
      read: activity.read,
      queue: mapQueue(activity.queue_log),
    };
  }

  function newActivity(activity: ActivityResponse) {
    activities.value.unshift(mapCallActivity(activity));
  }

  function mapActivityResponse(response: ActivityResponse[]) {
    for (const activity of response) {
      activities.value.push(mapCallActivity(activity));
    }

    activities.value.sort((a, b) => {
      return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
    });
  }

  function getCurrentActivities() {
    switch (activityType.value) {
      case 'call':
        return callActivities.value;
      case 'voicemail':
        return voicemailActivities.value;
      case 'all':
      default:
        return activities.value;
    }
  }

  function setGroupActivities() {
    groupedActivities.value = [];
    const currentActivities = getCurrentActivities();

    if (currentActivities.length === 0) return groupedActivities;

    for (let i = 0; i < currentActivities.length; i++) {
      const currentActivity = currentActivities[i];
      const currentGroupActivitiesIndex = groupedActivities.value.length - 1;
      const limitInHours = 1;

      if (i === 0) {
        groupedActivities.value.push([currentActivity]);
        continue;
      }

      const previousActivity = currentActivities[i - 1];

      if (
        currentActivity.type === 'call' &&
        currentActivity.from.number === previousActivity.from.number &&
        currentActivity.to.number === previousActivity.to.number &&
        currentActivity.type === previousActivity.type &&
        differenceInHours(
          new Date(
            groupedActivities.value[currentGroupActivitiesIndex][
              firstIndex
            ].startedAt,
          ),
          new Date(currentActivity.startedAt),
        ) <= limitInHours &&
        currentActivity.queue?.datetimeEnterQueue ===
          previousActivity.queue?.datetimeEnterQueue
      ) {
        groupedActivities.value[currentGroupActivitiesIndex].push(
          currentActivity,
        );
      } else {
        groupedActivities.value.push([currentActivity]);
      }
    }
  }

  function mapTranscription(
    transcription: string | null,
  ): CallTranscription | VoicemailTranscription | null {
    if (!transcription) return null;

    return JSON.parse(transcription);
  }

  function setAgentAnswered(agentAnswered: QueueLog['agentAnswered']) {
    if (!agentAnswered.number) return undefined;

    const accountCode = userStore.currentAccount.accountCode;
    const team =
      'switch_user_id' in agentAnswered.user &&
      accountCode === agentAnswered.user.account_code
        ? userStore.findTeamByNumber(agentAnswered.number)
        : undefined;

    return {
      number: agentAnswered.number,
      datetimeAnswered: agentAnswered.datetimeAnswered,
      datetimeFinished: agentAnswered.datetimeFinished,
      user: team
        ? {
            id: agentAnswered.user.switch_user_id,
            accountCode: agentAnswered.user.account_code,
            number: agentAnswered.number,
            name: team.userName,
            tag: null,
            isContact: false,
            avatar: {
              src: indexesStore.avatars[Number(team.userId)]
                ? indexesStore.avatars[Number(team.userId)][ImageSize.Default]
                : 'undefined',
              status:
                (statusStore?.status[Number(team.userId)]
                  ?.status as unknown as UserStatusEnum) ?? 'offline',
            },
            callerId: agentAnswered.user.callerid,
          }
        : undefined,
    };
  }

  function mapQueue(queue: string | null): null | ActivityQueue {
    if (!queue) return null;

    const queueParsed: QueueLog = JSON.parse(queue);

    return {
      datetimeEnterQueue: queueParsed.datetimeEnterQueue,
      abandon: queueParsed.abandon,
      datetimeAbandon: queueParsed.datetimeAbandon,
      secondsWaitedInQueue: queueParsed.secondsWaitedInQueue,
      secondsInTheCall: queueParsed.secondsInTheCall,
      agentAnswered: setAgentAnswered(queueParsed.agentAnswered),
      numberMissed: queueParsed.numberMissed,
      name: queueParsed.queuename,
    };
  }

  function getAvatar(
    activity: ActivityResponse,
    source: 'from' | 'to',
  ): {
    src: string;
    status: UserStatusEnum | undefined;
  } {
    const { src_user, dst_user, src_account_code, dst_account_code } = activity;
    const accountCode = userStore.currentAccount.accountCode;

    if (source === 'from') {
      return src_account_code === accountCode
        ? {
            src: indexesStore.avatars[src_user as number]
              ? indexesStore.avatars[src_user as number][ImageSize.Default]
              : 'undefined',
            status:
              (statusStore?.status[src_user as number]
                ?.status as unknown as UserStatusEnum) ?? 'offline',
          }
        : {
            src: '',
            status: undefined,
          };
    }
    return dst_account_code === accountCode
      ? {
          src: indexesStore.avatars[dst_user as number]
            ? indexesStore.avatars[dst_user as number][ImageSize.Default]
            : 'undefined',
          status:
            (statusStore?.status[dst_user as number]
              ?.status as unknown as UserStatusEnum) ?? 'offline',
        }
      : {
          src: '',
          status: undefined,
        };
  }

  function getInfo(activity: ActivityResponse, source: 'from' | 'to') {
    const {
      src_number,
      dst_number,
      src_account_code,
      dst_account_code,
      src_user,
      dst_user,
    } = activity;
    const accountCode = userStore.currentAccount.accountCode;
    const userId = userStore.user.userId;

    if (source === 'from') {
      if (src_account_code === accountCode) {
        const directory = userStore.directory((src_user as number).toString());
        return src_user === userId
          ? {
              name: userStore.user.userName,
              tag: '',
              isContact: false,
            }
          : {
              name: directory ? directory.userName : '',
              tag: '',
              isContact: false,
            };
      }

      const contact = userStore.findImportedContactByNumber(src_number);
      return contact
        ? {
            name: contact.phone.fullName,
            tag: contact.phone.tag,
            isContact: true,
          }
        : { name: null, tag: null, isContact: false };
    }

    if (dst_account_code === accountCode) {
      const directory = userStore.directory((dst_user as number).toString());
      return dst_user === userId
        ? {
            name: userStore.user.userName,
            tag: '',
            isContact: false,
          }
        : {
            name: directory ? directory.userName : '',
            tag: '',
            isContact: false,
          };
    }

    const contact = userStore.findImportedContactByNumber(dst_number);
    return contact
      ? {
          name: contact.phone.fullName,
          tag: contact.phone.tag,
          isContact: true,
        }
      : { name: null, tag: null, isContact: false };
  }

  function setDetails(releaseCause: string) {
    switch (releaseCause) {
      case '0017':
      case '0018':
        return 'Missed';
      case '0041':
        return 'Cancelled';
      default:
        return 'Normal';
    }
  }

  function setSelectedActivity(activity: Activity) {
    selectedActivity.value = activity;
  }

  function handleToggleCollapse(index: number) {
    groupedActivities.value[index][firstIndex].collapse =
      !groupedActivities.value[index][firstIndex].collapse;
  }

  function changeToActivity() {
    if (activityType.value === 'all') return;

    activityType.value = 'all';

    setGroupActivities();
  }

  function changeToCallActivity() {
    if (activityType.value === 'call') {
      changeToActivity();
      return;
    }

    activityType.value = 'call';
    setGroupActivities();
  }

  function changeToVoicemailActivity() {
    if (activityType.value === 'voicemail') {
      changeToActivity();
      return;
    }

    activityType.value = 'voicemail';
    setGroupActivities();
  }

  function updateStatus() {
    const accountCode = userStore.currentAccount.accountCode;

    for (const activity of activities.value) {
      const srcUser = activity.from.id;
      const dstUser = activity.to.id;

      activity.from.avatar.status =
        accountCode === activity.from.accountCode
          ? ((statusStore?.status[srcUser as number]
              ?.status as unknown as UserStatusEnum) ?? 'offline')
          : undefined;

      activity.to.avatar.status =
        accountCode === activity.to.accountCode
          ? ((statusStore?.status[dstUser as number]
              ?.status as unknown as UserStatusEnum) ?? 'offline')
          : undefined;

      if (
        activity.queue &&
        activity.queue.agentAnswered &&
        activity.queue.agentAnswered.user
      ) {
        activity.queue.agentAnswered.user.avatar.status =
          (statusStore?.status[activity.queue.agentAnswered.user.id as number]
            ?.status as unknown as UserStatusEnum) ?? 'offline';
      }
    }
    setGroupActivities();
  }

  async function getActivities() {
    const minimumAmountActivities = 50;

    if (activities.value.length < minimumAmountActivities) {
      activities.value = [];
      syncActivitiesInitial();
    }
  }

  function listenersActivity() {
    onNewActivity();
    onNewTranscription();
    onActivityRecordedFile();
  }

  watch(
    () => statusStore.status,
    () => {
      updateStatus();
    },
  );

  return {
    activities,
    callActivities,
    voicemailActivities,
    groupedActivities,
    activityType,
    selectedActivity,
    openPlayer,
    openDetails,
    openTranscription,
    lastActivities,
    moreActivities,
    lastGroupedActivities,
    unreadActivities,
    unreadCallActivities,
    unreadVoiceMailActivities,
    hasQueue,
    hasAgentAnswered,
    hasQueueUser,
    handleOpenPlayer,
    handleClosePlayer,
    handleOpenDetails,
    handleCloseDetails,
    handleOpenTranscription,
    handleCloseTranscription,
    setSelectedActivity,
    syncActivitiesInitial,
    syncActivitiesBatch,
    handleToggleCollapse,
    listenersActivity,
    changeToCallActivity,
    changeToVoicemailActivity,
    changeActivity: changeToActivity,
    getActivities,
    checkActivityRead,
    checkActivitiesRead,
    activitiesUnread,
  };
});
