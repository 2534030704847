import { defineStore } from 'pinia';
import type {
  Directory,
  DirectoryAvatars,
  DirectoryIndex,
  IndexesStore,
  RegisteredNumbers,
} from '@/types';
import { useUserStore } from '@/stores/user';
import { convertBlobToBase64 } from '@/utils';
import { increaseBadge, decreaseBadge } from '@/composables/use-notification';

export const useIndexesStore = defineStore('indexes', {
  state: (): IndexesStore => ({
    directories: {},
    unreadDirectoryMessages: {},
    unreadGroupMessages: {},
    unreadUnknownSmsMessages: {},
    scrollLock: true,
    avatars: {},
    registeredNumbers: {},
  }),
  actions: {
    setDirectoryIndex(directory: Directory) {
      const directories: DirectoryIndex = this.directories;
      directories[directory.userId] = directory;
      this.directories = { ...directories };
    },

    setUnreadDirectoryMessages(userId: number, quantity: number) {
      this.unreadDirectoryMessages[userId] = quantity;
      increaseBadge(quantity);
    },

    increaseDirectoryMessages(userId: number, quantity: number) {
      this.unreadDirectoryMessages[userId] =
        (this.unreadDirectoryMessages[userId] ?? 0) + quantity;
      increaseBadge(quantity);
    },

    clearUnreadDirectoryMessages(userId: number) {
      decreaseBadge(this.unreadDirectoryMessages[userId]);
      this.unreadDirectoryMessages[userId] = 0;
    },

    setUnreadGroupMessages(groupId: number, quantity: number) {
      this.unreadGroupMessages[groupId] = quantity;
      increaseBadge(quantity);
    },

    increaseGroupMessages(groupId: number, quantity: number) {
      this.unreadGroupMessages[groupId] =
        (this.unreadGroupMessages[groupId] ?? 0) + quantity;
      increaseBadge(quantity);
    },

    clearUnreadGroupMessages(groupId: number) {
      decreaseBadge(this.unreadGroupMessages[groupId]);
      this.unreadGroupMessages[groupId] = 0;
    },

    async setAvatarImage(userId: number) {
      const userStore = useUserStore();
      const avatars: DirectoryAvatars = this.avatars;

      avatars[userId] = {};

      for (const size of [20, 32, 80]) {
        try {
          this.getImageFromPath(
            `${
              userStore.publicStorage
            }/avatar/${userId}_${size}x${size}.jpg?${Date.now()}`,
          )
            .then(async (blob) => {
              const img = await convertBlobToBase64(blob as Blob);
              if (typeof img === 'string') {
                avatars[userId][size] = img;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.error({ err });
        }
      }

      this.avatars = { ...avatars };
    },

    getImageFromPath(imagePath: string) {
      return new Promise((resolve, reject) => {
        fetch(imagePath)
          .then((response) => {
            if (!response.ok || response.status === 204) {
              throw new Error(`Failed to fetch image: ${imagePath}`);
            }
            return response.blob();
          })
          .then((blob) => resolve(blob))
          .catch((error) => reject(error));
      });
    },

    updateNumberRegistering(number: string, active: boolean) {
      const numberRegistering: RegisteredNumbers = this.registeredNumbers;

      numberRegistering[number] = active;
      this.registeredNumbers = { ...numberRegistering };
    },

    enableScrollLock() {
      this.scrollLock = true;
    },

    disableScrollLock() {
      this.scrollLock = false;
    },

    setUnreadUnknownSmsMessages(number: string, quantity: number) {
      this.unreadUnknownSmsMessages[number] = quantity;
      increaseBadge(quantity);
    },

    increaseUnreadUnknownSmsMessages(number: string, quantity: number) {
      this.unreadUnknownSmsMessages[number] =
        (this.unreadUnknownSmsMessages[number] ?? 0) + quantity;
      increaseBadge(quantity);
    },

    clearUnreadUnknownSmsMessages(number: string) {
      decreaseBadge(this.unreadUnknownSmsMessages[number]);
      this.unreadUnknownSmsMessages[number] = 0;
    },
  },
  persist: false,
});
