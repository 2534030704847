import { useAttachmentStore, useUserStore } from '@/stores';
import type { AxiosError, AxiosProgressEvent } from 'axios';
import type {
  ApiDataException,
  PutFileResponseData,
  SettingsAttachmentResponseData,
  SuccessResponseData,
} from '@/types';
import { uploadFileApi } from '@/lib';
import { EndpointApi } from '@/enums';

interface PutFile {
  file: File;
  to: string;
  onProgress: (event: AxiosProgressEvent) => void;
  signal: AbortSignal;
}

interface PutGroupFile {
  file: File;
  groupId: string;
  onProgress: (event: AxiosProgressEvent) => void;
  signal: AbortSignal;
}

export function useAttachments() {
  async function putFile({ file, to, onProgress, signal }: PutFile) {
    const userStore = useUserStore();

    try {
      const data = new FormData();
      data.append('file', file);
      data.append('account_code', userStore.currentAccount.accountCode);
      data.append('user_to', to);

      const response = await uploadFileApi.post<PutFileResponseData>(
        EndpointApi.PutFile,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userStore.user.authToken}`,
          },
          onUploadProgress: onProgress,
          signal,
        },
      );

      return response.data;
    } catch (err) {
      throw err as AxiosError<ApiDataException>;
    }
  }

  async function putGroupFile({
    file,
    groupId,
    onProgress,
    signal,
  }: PutGroupFile) {
    const userStore = useUserStore();

    try {
      const data = new FormData();
      data.append('file', file);
      data.append('account_code', userStore.currentAccount.accountCode);

      const response = await uploadFileApi.post<PutFileResponseData>(
        `${EndpointApi.PutGroupFile}/${groupId}/put-file`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userStore.user.authToken}`,
          },
          onUploadProgress: onProgress,
          signal,
        },
      );

      return response.data;
    } catch (err) {
      throw err as AxiosError<ApiDataException>;
    }
  }

  async function downloadFile(hash: string, filename: string) {
    const userStore = useUserStore();

    const response = await uploadFileApi.get<SuccessResponseData>(
      `${EndpointApi.DownloadFile}/${hash}`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${userStore.user.authToken}`,
          'account-code': userStore.currentAccount.accountCode,
        },
      },
    );

    const data = response.data as unknown as Blob;
    const href = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  async function getAttachmentsSettings() {
    const userStore = useUserStore();
    const { setSettingsAttachment } = useAttachmentStore();

    try {
      const response = await uploadFileApi.get<SettingsAttachmentResponseData>(
        EndpointApi.Settings,
        {
          headers: {
            Authorization: `Bearer ${userStore.user.authToken}`,
            'account-code': userStore.currentAccount.accountCode,
          },
        },
      );

      const data = response.data;
      setSettingsAttachment({
        maxSizeFileBytes: data.max_size_file_bytes,
        forbiddenFileTypes: data.forbidden_file_types,
      });
    } catch (err) {
      throw err as AxiosError<ApiDataException>;
    }
  }

  return {
    putFile,
    putGroupFile,
    downloadFile,
    getAttachmentsSettings,
  };
}
