import axios, { AxiosError } from 'axios';
import type {
  ApiDataException,
  Directory,
  DirectoryResponseData,
} from '@/types';
import { EndpointApi } from '@/enums';
import { useUserStore } from '@/stores';
import { useIndexesStore } from '@/stores/indexes';

export async function useDirectory() {
  const userStore = useUserStore();
  const indexStore = useIndexesStore();

  try {
    const response = await axios.get<DirectoryResponseData>(
      EndpointApi.Directory,
    );
    const data = response.data.directories;
    userStore.directories = [];
    for (const directory of data) {
      if (userStore.user.userId !== directory.user_id) {
        const path = `avatar/${directory.user_id}`;

        const directoryInfo: Directory = {
          userId: directory.user_id,
          userName: directory.user_name,
          userType: directory.user_type,
          accountCode: directory.account_code,
          image: {
            20: `${path}_20x20.jpg`,
            32: `${path}_32x32.jpg`,
            80: `${path}_80x80.jpg`,
          },
        };

        if (directory.settings && Object.keys(directory.settings).length > 0) {
          directoryInfo.settings = {
            fullName: directory.settings.full_name,
            userType: directory.settings.user_type,
            jobTitle: directory.settings.job_title,
            company: directory.settings.company,
            birthday: directory.settings.birthday,
            emailType: directory.settings.email_type,
            email: directory.settings.email,
            addressType: directory.settings.address_type,
            address1: directory.settings.address1,
            address2: directory.settings.address2,
            city: directory.settings.city,
            state: directory.settings.state,
          };
        }

        userStore.setDirectory(directoryInfo);
        indexStore.setDirectoryIndex(directoryInfo);
        indexStore.setAvatarImage(directoryInfo.userId);
      }
    }

    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}
