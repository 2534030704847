import { computed } from 'vue';
import type { Group } from '@/types';

export function useGroup() {
  const participants = computed(() => {
    return (group: Group, currentId: number) => {
      const names = group.users
        .filter((user) => user.id !== currentId)
        .map((user) => user.name);
      return names.join(', ');
    };
  });

  return {
    participants,
  };
}
