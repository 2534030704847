import { useUserStore } from '@/stores';
import axios, { AxiosError } from 'axios';
import type {
  ApiDataException,
  SetImportedContactsResponseData,
  GetImportedContactsResponseData,
  ContactSwitch,
  ProviderImportedContact,
  SuccessResponseData,
  StoreContactResponseData,
  EditContactResponseData, SuccessSetImportedContactsResponseData, SuccessSetImportedDirectoryContactsResponseData,
} from '@/types';
import { EndpointApi } from '@/enums';

export async function useNewImportedContacts(contacts: ContactSwitch[]) {
  if (contacts.length === 0) return;
  try {
    const response = await axios.post<SetImportedContactsResponseData>(
      EndpointApi.NewImportedContacts,
      { contacts },
    );
    await useGetImportedContacts();
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useGetImportedContacts() {
  const userStore = useUserStore();
  userStore.importedContacts = [];
  try {
    const response = await axios.get<GetImportedContactsResponseData>(
      EndpointApi.GetImportedContacts,
    );
    const contacts = response.data.contacts;
    if (contacts.length === 0) return;
    for await (const contact of contacts) {
      if(Object.prototype.hasOwnProperty.call(contact, 'type')) {
        userStore.setImportedContacts(contact as SuccessSetImportedContactsResponseData);
      }

      if(Object.prototype.hasOwnProperty.call(contact, 'user_name')) {
        userStore.setImportedDirectoryContacts(contact as  SuccessSetImportedDirectoryContactsResponseData, String(userStore.user.userId));
      }
    }
    userStore.setSourceEmailProvider();
    userStore.sortImportedContactsByNameAsc();
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useDeleteImportedContactsByProvider(
  provider: ProviderImportedContact,
) {
  const userStore = useUserStore();
  try {
    const response = await axios.post<SuccessResponseData>(
      EndpointApi.DeleteImportedContacts,
      {
        provider,
      },
    );
    userStore.removeImportedContactsByProvider(provider);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useStoreContact(contact: ContactSwitch) {
  const userStore = useUserStore();
  try {
    const response = await axios.post<StoreContactResponseData>(
      EndpointApi.StoreContact,
      contact,
    );
    console.log({ response });
    userStore.setImportedContacts(response.data.contact);
    userStore.sortImportedContactsByNameAsc();
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useEditContact(contact: ContactSwitch, id: number) {
  const userStore = useUserStore();
  try {
    const response = await axios.put<EditContactResponseData>(
      `${EndpointApi.Contact}/${id}/edit`,
      contact,
    );
    userStore.updateContact(id, response.data.contact);
    userStore.sortImportedContactsByNameAsc();
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useEditDirectoryContact(contact: ContactSwitch, id: number) {
  const userStore = useUserStore();
  try {
    const response = await axios.put<EditContactResponseData>(
      `${EndpointApi.Contact}/edit-directory`,
      contact,
    );
    userStore.updateContact(id, response.data.contact);
    userStore.sortImportedContactsByNameAsc();
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useDeleteContact(id: number) {
  const userStore = useUserStore();
  try {
    const response = await axios.delete<SuccessResponseData>(
      `${EndpointApi.Contact}/${id}/delete`,
    );
    userStore.deleteContact(id);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}
