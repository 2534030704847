import { computed, ref } from 'vue';
import { addThreeDots, db, removeMessageInEffect } from '@/utils';
import { defineStore } from 'pinia';
import {
  useSocketConnectionStore,
  useUserStore,
  useConferenceStore,
  useIndexesStore,
  useToastStore,
} from '@/stores';
import type { GroupMessage } from '@/utils/db/types';
import { useRoute, useRouter } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';
import {
  useArray,
  useContact,
  useGroup,
  useImage,
  useNotification,
} from '@/composables';
import { RouteName } from '@/enums';
import { useI18n } from 'vue-i18n';
import type { FileUploadData, Group, RenameGroup } from '@/types';
import { collect } from 'collect.js';

interface NewConference {
  token: string;
  message: GroupMessage;
}

interface PostGroupAttachment {
  message?: string;
  files: FileUploadData[];
}

interface EmitRenameGroup {
  name: string | undefined;
  users: number[];
  groupId: number;
}

export const useGroupMessageStore = defineStore('group-message', () => {
  const router = useRouter();
  const route = useRoute();
  const { imgBase64, setIconAvatar } = useImage();
  const { t } = useI18n();
  const { participants } = useGroup();
  const toastStore = useToastStore();

  const socketConnectionStore = useSocketConnectionStore();
  const userStore = useUserStore();
  const indexesStore = useIndexesStore();
  const socket = computed(() => socketConnectionStore.ioClient);
  const conferenceStore = useConferenceStore();
  const { addRecentGroup } = useContact();

  const groupId = computed(() => Number(route.params.id));

  const messages = ref<GroupMessage[]>([]);
  const firstMessage = ref<GroupMessage | null>(null);

  async function insertGroupMessage(groupMessage: GroupMessage) {
    return db.group_messages.put(groupMessage);
  }

  function newGroupMessage(content: string, users: number[]) {
    socket.value?.emit(
      'post-message-group',
      {
        src_user: userStore.user.userId,
        group_id: groupId.value,
        account_code: userStore.currentAccount.accountCode,
        content,
        type: 'message',
        users,
      },
      async (response: GroupMessage) => {
        await insertGroupMessage(response);
        //messages.value = await getMessagesDB();
        updateChatMessage([response]);
      },
    );
  }

  function newGroupMessageAttachment({ message, files }: PostGroupAttachment) {
    const filesAtt = [];
    for (const file of files) {
      filesAtt.push({
        hash: file.hash,
        filename: file.name,
        filetype: file.filetype,
        size: file.size.toString(),
      });
    }
    socket.value?.emit(
      'post-attachment-group',
      {
        src_user: userStore.user.userId,
        group_id: groupId.value,
        account_code: userStore.currentAccount.accountCode,
        message,
        files: filesAtt,
      },
      async (response: GroupMessage) => {
        await insertGroupMessage(response);
        messages.value = await getMessagesDB();
      },
    );
  }

  function deleteGroupAttachment(hash: string, createdAt: string | Date) {
    socket.value?.emit(
      'delete-attachment-group',
      {
        group_id: groupId.value,
        account_code: userStore.currentAccount.accountCode,
        created_at: createdAt,
        hash,
      },
      async (response: GroupMessage) => {
        await insertGroupMessage(response);
        //messages.value = await getMessagesDB();
        updateChatMessage([response]);
      },
    );
  }

  async function getMessagesDB() {
    if (!groupId.value) return [];
    return db.group_messages.where({ group_id: groupId.value }).toArray();
  }

  async function getMessages() {
    return new Promise((resolve) => {
      socket.value?.emit(
        'sync-group-initial',
        {
          account_code: userStore.currentAccount.accountCode,
          group_id: groupId.value,
        },
        async (response: { data: GroupMessage[] }) => {
          await db.group_messages.bulkPut(response.data);
          //messages.value = await getMessagesDB();
          messages.value = response.data;
          resolve(response.data);
        },
      );
    });
  }

  function searchMessage(dateRef: string | Date, groupId: number) {
    socket.value?.emit(
      'search-group-message',
      {
        account_code: userStore.currentAccount.accountCode,
        group_id: groupId,
        data_ref: dateRef,
      },
      async (response: { data: GroupMessage[] }) => {
        await db.group_messages.bulkPut(response.data);
        //messages.value = await getMessagesDB();
        messages.value = response.data;
      },
    );
  }

  function newMessageGroupReceived() {
    const id = route.params.id as string;

    socket.value?.on('new-message-group', async (response: GroupMessage) => {
      socket.value?.emit('message-group-received', {
        ...response,
        user: userStore.user.userId,
      });
      await db.group_messages.put(response);

      updateChatMessage([response]);
      indexesStore.increaseGroupMessages(response.group_id, 1);

      addRecentGroup(String(response.group_id));

      if (
        indexesStore.scrollLock &&
        route.name === RouteName.Group &&
        parseInt(id) === groupId.value
      ) {
        return;
      }

      const group = userStore.groupById(response.group_id);
      await setIconAvatar(response.src_user);
      const title = group
        ? t('new-message-group', {
            group: addThreeDots(
              participants.value(group, userStore.user.userId),
              20,
            ),
          })
        : 'New Message';
      const url: RouteLocationRaw = {
        name: RouteName.Group,
        params: {
          id: response.group_id,
        },
        query: {
          messageId: response.id,
        },
      };

      function getContent(messageType: GroupMessage['type']) {
        let content: string;
        switch (messageType) {
          case 'attachment': {
            content = JSON.parse(response.content).message;
            content = content ? content : ' sent an attachment';
            break;
          }
          default:
            content = `: ${response.content}`;
        }

        return content;
      }

      const content = addThreeDots(
        `${indexesStore.directories[response.src_user].userName}${getContent(
          response.type,
        )}`,
        150,
      );
      const body =
        new DOMParser().parseFromString(content, 'text/html').body
          .textContent ?? '';

      if (userStore.onlineStatus === 'dnd') return;

      if (route.name === RouteName.Group && response.group_id === groupId.value)
        return;

      useNotification({
        title,
        body,
        url,
        router,
      });
      toastStore.newMessage({
        id: window.crypto.randomUUID(),
        title: group
          ? participants.value(group, userStore.user.userId)
          : t('New Message'),
        message: body,
        type: 'group',
        url,
        avatarUrl: imgBase64.value,
        variant: 'message',
      });
    });
  }

  async function getBatchGroupMessages(operation: 'oldest' | 'newest') {
    if (messages.value.length === 0) return;

    const messagesSorted = collect(messages.value).sortBy('created_at').all();
    const firstMessage = messagesSorted[0].created_at;
    const lastMessage = messagesSorted[messagesSorted.length - 1].created_at;

    socket.value?.emit(
      'sync-get-group-batch',
      {
        account_code: userStore.currentAccount.accountCode,
        group_id: groupId.value,
        data_ref: operation === 'oldest' ? firstMessage : lastMessage,
        operation,
      },
      async (response: { data: GroupMessage[] }) => {
        await db.group_messages.bulkPut(response.data);
        //messages.value = await getMessagesDB();
        updateChatMessage(response.data);
      },
    );
  }

  function updateChatMessage(newMessages: GroupMessage[]) {
    if (!groupId.value || newMessages[0].group_id !== groupId.value) return;
    const temporaryMessages = messages.value;
    newMessages.forEach((message) => {
      const index = temporaryMessages.findIndex(
        (messagePinia) => messagePinia.id === message.id,
      );

      if (index !== -1) {
        temporaryMessages[index] = message;
      } else {
        temporaryMessages.push(message);
      }
    });

    messages.value = [...temporaryMessages];
  }

  function newGroupConference(groupId: number, users: number[]) {
    socket.value?.emit(
      'new-group-conference',
      {
        group_id: parseInt(groupId.toString()),
        account_code: userStore.currentAccount.accountCode,
        users,
        src_user: userStore.user.userId, //who sent the invite
      },
      async (response: NewConference) => {
        await insertGroupMessage(response.message);
        //messages.value = await getMessagesDB();
        updateChatMessage([response.message]);
        conferenceStore.openNewConferenceModal(response.token);
      },
    );
  }

  function acceptConference(response: NewConference) {
    socket.value?.emit(
      'accept-group-conference',
      {
        message: response.message,
        user: userStore.user.userId,
        users: userStore.groupUsersById(
          parseInt(response.message.group_id.toString()),
        ),
      },
      async (params: { message: GroupMessage }) => {
        await insertGroupMessage(params.message);
        //messages.value = await getMessagesDB();
        updateChatMessage([response.message]);
      },
    );
    conferenceStore.openNewConferenceModal(response.token);
  }

  function onAcceptConference() {
    socket.value?.on(
      'accept-group-conference',
      async (params: { message: GroupMessage }) => {
        await insertGroupMessage(params.message);
        //messages.value = await getMessagesDB();
        updateChatMessage([params.message]);
      },
    );
  }

  function onUpdateConference() {
    socket.value?.on(
      'update-group-meeting',
      async (params: { message: GroupMessage }) => {
        await insertGroupMessage(params.message);
        //messages.value = await getMessagesDB();
        updateChatMessage([params.message]);
      },
    );
  }

  async function saveMessage(message: GroupMessage) {
    await insertGroupMessage(message);
    //messages.value = await getMessagesDB();
    updateChatMessage([message]);
  }

  function openConference() {
    socket.value?.on(
      'open-group-conference',
      async (response: NewConference) => {
        await insertGroupMessage(response.message);
        //messages.value = await getMessagesDB();
        updateChatMessage([response.message]);
        const group = userStore.groupById(response.message.group_id);
        await setIconAvatar(response.message.src_user);
        const title = group
          ? participants.value(group, userStore.user.userId)
          : t('New meeting');
        const userName =
          indexesStore.directories[response.message.src_user].userName;
        const message = `${userName} started a meeting`;
        toastStore.newMeeting({
          type: 'group',
          title,
          message,
          groupConference: response,
          id: window.crypto.randomUUID(),
          avatarUrl: imgBase64.value,
          variant: 'meeting',
        });
      },
    );
  }

  function readAllMessagesByGroupId(groupId: string) {
    socket.value?.emit('message-group-read', {
      user: userStore.user.userId,
      group_id: parseInt(groupId),
      account_code: userStore.currentAccount.accountCode,
    });
    indexesStore.clearUnreadGroupMessages(parseInt(groupId));
  }

  function emitNewGroup(group: Group) {
    socket.value?.emit('new-group', {
      account_code: userStore.currentAccount.accountCode,
      group,
      users: userStore.groupUsersById(group.id),
    });
  }

  function onNewGroup() {
    socket.value?.on('new-group', (group: Group) => {
      userStore.setGroup(group);
    });
  }

  function editGroupMessage(
    groupId: number,
    createdAt: Date,
    newMessage: string,
  ) {
    socket.value?.emit(
      'edit-group-message',
      {
        group_id: groupId,
        account_code: userStore.currentAccount.accountCode,
        created_at: createdAt,
        src_user: userStore.user.userId,
        new_message: newMessage,
      },
      async (message: GroupMessage) => {
        await insertGroupMessage(message);
        updateChatMessage([message]);
      },
    );
  }

  function onEditGroupMessage() {
    socket.value?.on('edit-group-message', async (message: GroupMessage) => {
      await insertGroupMessage(message);
      updateChatMessage([message]);
    });
  }

  async function removeGroupMessage(id: string) {
    const { removeElementByIndex, findIndexByValue } = useArray(messages.value);
    const index = findIndexByValue('id', id);
    removeElementByIndex(index);

    return db.group_messages.where({ id }).delete();
  }

  function deleteGroupMessage(groupId: number, createdAt: Date) {
    socket.value?.emit(
      'delete-group-message',
      {
        group_id: groupId,
        account_code: userStore.currentAccount.accountCode,
        created_at: createdAt,
        src_user: userStore.user.userId,
      },
      async (message: GroupMessage) => {
        await removeMessageInEffect(message.id);
        await removeGroupMessage(message.id);
      },
    );
  }

  function onDeleteGroupMessage() {
    socket.value?.on('delete-group-message', async (message: GroupMessage) => {
      await removeMessageInEffect(message.id);
      await removeGroupMessage(message.id);

      const unreadMessages = indexesStore.unreadGroupMessages[message.group_id];
      for (const { read } of message.read) {
        indexesStore.unreadGroupMessages[message.group_id] =
          unreadMessages > 0 && !read ? unreadMessages - 1 : 0;
      }
    });
  }

  function emitRenameGroup(data: EmitRenameGroup) {
    const { users, name, groupId } = data;

    socket.value?.emit('rename-group', {
      account_code: userStore.currentAccount.accountCode,
      name,
      users,
      group_id: groupId,
    });
  }

  function onNewRenameGroup() {
    socket.value?.on('rename-group', (data: RenameGroup) => {
      const { group_id: groupId, name } = data;
      userStore.setGroupName(groupId.toString(), name);
    });
  }

  function onReloadMessages() {
    socket.value?.on('reconnect', async () => {
      if (route.name !== RouteName.Group) return;

      await getMessages();
    });
  }

  async function getFirstMessage() {
    return new Promise((resolve) => {
      socket.value?.emit(
        'group-first-message',
        {
          group_id: groupId.value,
          account_code: userStore.currentAccount.accountCode,
        },
        (message: GroupMessage | null) => {
          resolve((firstMessage.value = message));
        },
      );
    });
  }

  function listenersGroupMessage() {
    newMessageGroupReceived();
    openConference();
    onNewGroup();
    onAcceptConference();
    onUpdateConference();
    onEditGroupMessage();
    onDeleteGroupMessage();
    onNewRenameGroup();
    onReloadMessages();
  }

  return {
    messages,
    firstMessage,
    acceptConference,
    newGroupMessage,
    newGroupMessageAttachment,
    deleteGroupAttachment,
    getMessages,
    getBatchGroupMessages,
    listenersGroupMessage,
    newGroupConference,
    readAllMessagesByGroupId,
    emitNewGroup,
    saveMessage,
    searchMessage,
    editGroupMessage,
    deleteGroupMessage,
    emitRenameGroup,
    getFirstMessage,
  };
});
