import Dexie, { type Table } from 'dexie';
import type {
  Numbers,
  ChatMessage,
  GroupMessage,
  UnknownSms,
} from '@/utils/db/types';

export class MySubClassedDexie extends Dexie {
  numbers!: Table<Numbers>;
  chat_messages!: Table<ChatMessage>;
  group_messages!: Table<GroupMessage>;
  unknown_sms!: Table<UnknownSms>;

  constructor() {
    super('db-switch');
    this.version(3).stores({
      numbers:
        '++id,contact_id,number,signed_in,account_code,registered,default,description',
      chat_messages:
        'id,account_code,content,src_user,dst_user,read,type,created_at,updated_at,[src_user+account_code], [dst_user+account_code]',
      group_messages:
        'id,account_code,group_id,content,src_user,read,type,created_at,updated_at, [group_id+account_code]',
      unknown_sms:
        'id,content,src_number,src_user,dst_number,status,created_at',
    });
  }
}

export const db = new MySubClassedDexie();

export async function removeDatabase() {
  return db.delete();
}

export function closeDB() {
  return db.close();
}
